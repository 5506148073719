import React from "react";
import { graphql, useStaticQuery } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import FaqAccordion from "../../../components/faq/faq-accordion";
import HelpSection from "../../../components/home-loans/help-section";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";
import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import CustomLargeCards from "../../../components/card/custom-large-cards";
import NewUIWafdBankBenefits from "../../../components/wafd-bank-benefits/new-ui-wafd-bank-benefits";
import PreApprovalWorkflow from "../../../components/home-loans/pre-approval-workflow";

const FirstTimeHomeBuyerLoans = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/first-time-home-buyer/hero-first-time-buyer-12202022-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/first-time-home-buyer/hero-first-time-buyer-12202022-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/first-time-home-buyer/hero-first-time-buyer-12202022-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/first-time-home-buyer/hero-first-time-buyer-12202022-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/first-time-home-buyer/hero-first-time-buyer-12202022-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/first-time-home-buyer/hero-first-time-buyer-12202022-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/first-time-home-buyer/hero-first-time-buyer-12202022-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      card01: file(relativePath: { eq: "cards/thumbnail-12-steps-to-buying-a-home-112923.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 400, layout: CONSTRAINED)
        }
      }
      card02: file(relativePath: { eq: "cards/thumbnail-approve-for-mortgage-04182023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 400, layout: CONSTRAINED)
        }
      }
      card03: file(relativePath: { eq: "cards/thumbnail-first-time-home-buyers-112123.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 400, layout: CONSTRAINED)
        }
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/personalization/smart-start-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const title = "First Time Home Buyer Home Loan Calculator",
    description =
      "Learn about first time home buyer home loans from WaFd Bank including the different home loans we offer, first time home buyer calculator and more.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/first-time-home-buyer-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-first-time-buyer-12202022-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "first-time-home-buyer-hero",
    ...getHeroImgVariables(pageData, ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"]),
    altText: "Young couple having first meal in new house.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Make Your Move With a Best Bank"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-1",
            text: "See Today's Rates",
            url: "/personal-banking/home-loans/mortgage-rates",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-2",
            text: "Use Loan Calculator",
            url: "#mortgage-calculator-section",
            class: "btn-light",
            containerClass: "mb-3",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "First Time Home Buyer Loans"
    }
  ];

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "Family playing with picture frame in new home."
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open a WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit, defined as at least one monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "Smart Start Home Loan",
      subtitle:
        "If you're a first-time home buyer, finding and buying a house can seem daunting in this market. We believe an affordable home is the gateway to long-term and short-term financial success, and with a Smart Start Home Loan, we can help make your dream of homeownership happen.",
      mainButton: {
        url: "/personal-banking/home-loans/smart-start-down-payment-assistance#do-you-qualify-calculator",
        title: "See If I Qualify"
      },
      secondaryButton: {
        url: "/personal-banking/home-loans/smart-start-down-payment-assistance",
        title: "Learn About Smart Start"
      }
    }
  ];

  const cardsData = useCardBlogsData([
    "/blog/home-ownership/12-steps-buying-house",
    "/blog/home-ownership/what-do-mortgage-lenders-look-for",
    "/blog/home-ownership/best-way-to-buy-a-house"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const linkCardsData = {
    sectionClass: "bg-white py-0",
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  const AVG_PRICE = 350000;
  const calculatorData = {
    title: "First Time Home Buyer Loan",
    loanType: "Home Loan",
    loanTerm: "30 Year Fixed",
    interestRate: 0,
    estimatedPayment: 1181,
    purchasePrice: AVG_PRICE,
    downPaymentPercent: 20,
    downPaymentAmount: AVG_PRICE * 0.2,
    loanAmount: AVG_PRICE - AVG_PRICE * 0.2,
    disclaimersSectionClass: "pt-0"
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you're a first time home buyer and have done all your research but want help, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have."
  };

  const faqAccordionData = {
    id: "first-time-homebuyer-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "first-time-homebuyer-see-more-faqs-link",
    faqCategoryNames: ["Home Loans"]
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container pb-0">
        <h1>First Time Home Buyer</h1>
        <h3>
          If you're buying your first home, you probably have a few questions. We're here to help! Our local loan
          officers will guide you from start to finish. We make it easy to submit documents and receive status updates
          with our convenient portal.
        </h3>
        <ReturningBorrowerLink loanType="First Time Home Buyer Loan" />
      </section>

      <MortgageCalculator {...calculatorData} />
      <PreApprovalWorkflow />

      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />

      <NewUIWafdBankBenefits />

      <FaqAccordion {...faqAccordionData} />

      <StretchedLinkCards {...linkCardsData} />

      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default FirstTimeHomeBuyerLoans;
